@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

$plus-jakarta-sans: "Plus Jakarta Sans", sans-serif;
$open-sans: "Open Sans", sans-serif;

a,
h2,
h3,
h4,
td,
.ui.message {
  font-family: $open-sans !important;
}

h1 {
  font-family: $plus-jakarta-sans !important;
}
.ui.basic.table {
  border: none !important;
  border-collapse: separate !important;
  border-spacing: 0 10px;
  tbody tr {
    border: none !important;
    margin-top: 20px !important;
    background-color: #fff !important;
    td {
      border: none !important;
      padding: 10px !important;
    }
    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

a {
  color: #34d1bf !important;
  i {
    color: #fff !important;
  }
}
a:hover {
  opacity: 0.8;
}

.ui.primary.button {
  background-color: #003f88 !important;
}

button {
  border-radius: 10px !important;
  font-family: $open-sans !important;
}
.bannerWrapper {
  background: linear-gradient(103deg, #00296b -5.41%, #496ddb 100.42%);
  min-height: 100vh;
  width: 100% !important;
}
.navbarMenu {
  background-color: transparent !important;
}
h1 {
  color: #fff;
  font-family: $plus-jakarta-sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  span {
    background: linear-gradient(271deg, #f4e900 59.09%, #60b6f1 100.84%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Plus Jakarta Sans";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}
p {
  color: #fff;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contentBannerWrapper {
  min-height: 85vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.playBtn {
  padding: 20px 84px 20px 84px !important;
  background: linear-gradient(270deg, #ffd600 1.45%, #60b6f1 98.92%) !important;
  color: #fff !important;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.16px;
  margin-right: 20px !important;
  line-height: 36px; /* 180% */
  border-radius: 10px !important;
}

.playBtn:hover {
  opacity: 0.8;
  color: #fff !important;
}
.ui.massive.basic.button.leaderboardBtn {
  padding: 20px 84px 20px 84px !important;
  border: 1px solid #fff !important;
  color: #fff !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 180% */
  margin-top: 10px;
}
.ui.massive.basic.button.leaderboardBtn:hover {
  opacity: 0.8;
  background: transparent !important;
}
.ui.massive.basic.button.leaderboardBtn:focus {
  opacity: 0.8;
  background: transparent !important;
}
.edImage {
  animation: breath 3s infinite;
}

@keyframes breath {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.footerWrapper {
  background: #003f88 !important;
  padding: 40px 20px 40px 20px !important;
}
.contactWrapper {
  a {
    display: flex !important;
  }
  i {
    color: #34d1bf !important;
    text-align: center !important;
  }
}
.leaderboardWrapper {
  margin-top: 40px;
  padding-bottom: 40px;
}
.leaderboardTitle {
  font-family: $plus-jakarta-sans !important;
  color: #003f88 !important;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.leaderboardSegmentSearch {
  background-color: #abf5ff !important;
  border-radius: 5px !important;
  padding: 40px !important;
}
.leaderboardDropdown > .ui.dropdown {
  background-color: #fdc500;
  .divider.default.text {
    color: #00296b;
    font-family: $open-sans !important;
    font-style: normal;
  }
  .item {
    font-family: $open-sans !important;
    font-style: normal;
    font-size: 20px;
  }
}
.searchInput {
  .ui.action.input {
    border-radius: 5px !important;
  }
  .header {
    font-family: $open-sans !important;
  }
  input {
    font-family: $open-sans !important;
    font-style: normal;
    color: rgba(34, 46, 58, 0.3);
    border-radius: 5px !important;
  }
  button {
    border-radius: 0px 5px 5px 0px !important;
    background-color: #fdc500 !important;
    width: 50px;
    display: flex !important;
    justify-content: center !important;
  }
  .result {
    p {
      color: #00296b;
    }
  }
}
.goToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.goToTop.visible {
  opacity: 1;
}
.resultSegment {
  border-radius: 5px !important;
  background: #fff !important;
  .ui.grid {
    align-items: center !important;
  }
}
.ui.info.message {
  background: #f8ffff !important;
}
.ui.basic.celled.structured.table.tableAlliance {
  thead tr {
    th {
      border: 0;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    }
    th:not(:first-child) {
      border-left: 1px solid rgba(34, 36, 38, 0.1);
    }
  }
  thead tr:last-child {
    th {
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    }
    th:first-child {
      border-left: 1px solid rgba(34, 36, 38, 0.1);
    }
  }
}
.leaderboardSegmentSearch {
  .ui.container.dimmed.dimmable {
    .resultSegment {
      cursor: pointer;
      h2 {
        text-align: left;
      }
    }
  }
}

// Tablet pro
@media screen and (max-width: 1024px) and (min-width: 1023px) {
  .bannerWrapper {
    min-height: 0;
  }
  .contentBannerWrapper {
    margin-top: 70px;
    min-height: 0 !important;
    padding-bottom: 30px;
  }
  h1 {
    font-size: 40px;
    span {
      font-size: 40px;
    }
  }
  .edImage {
    margin-top: -50px !important;
  }
  .playBtn,
  .ui.massive.basic.button.leaderboardBtn {
    padding: 20px 42px 20px 42px !important;
  }
  .leaderboardSegmentSearch {
    h1 {
      font-size: 24px !important;
    }
    .ui.container.dimmed.dimmable {
      margin: 0 !important;
      .resultSegment {
        margin-bottom: 0px !important;
        h2 {
          text-align: left;
          font-size: 16px !important;
        }
      }
    }
  }
}

// Tablet
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .btnWrapperBanner {
    display: none !important;
  }
  h1 {
    /* Tablet styles */
    font-size: 64px !important;
    text-align: center;
    span {
      font-size: 64px !important;
    }
  }
  p {
    text-align: center;
  }
  .edImage {
    margin-top: 0 !important;
    width: 400px;
  }
  .navbarMenu {
    .ui.container {
      display: flex;
      justify-content: center;
    }
  }
  .mBtnWrapper {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .playBtn,
  .ui.massive.basic.button.leaderboardBtn {
    margin-bottom: 10px !important;
    margin-right: 0 !important;
    width: 60% !important;
    margin-top: 0;
  }
  .socialIconWrapper {
    text-align: left !important;
  }
  .detailWrapperLeaderboard {
    .column {
      h1 {
        text-align: left !important;
        font-size: 24px !important;
      }
    }
  }
  .leaderboardTitle {
    font-size: 24px !important;
  }
  .leaderboardSegmentSearch {
    h1 {
      font-size: 24px !important;
    }
    .ui.container.dimmed.dimmable {
      margin: 0 !important;
      .resultSegment {
        margin-bottom: 0px !important;
        h2 {
          text-align: left;
          font-size: 16px !important;
        }
      }
    }
  }
  .searchInput {
    .result p {
      text-align: left !important;
    }
  }
  .ui.basic.celled.structured.table.tableAlliance {
    tr {
      display: flex !important;
    }
    thead tr:last-child {
      justify-content: flex-end !important;
    }
    tbody tr {
      td:nth-child(1),
      td:nth-child(4),
      td:nth-child(5),
      td:nth-child(6),
      td:last-child {
        flex: 0 0 auto !important;
        width: 10% !important;
        text-align: center;
      }
      td:nth-child(2),
      td:nth-child(3) {
        flex: 1 1 auto !important;
        width: 20% !important;
        word-wrap: break-word !important;
      }
    }
    thead tr:first-child {
      th:nth-child(1),
      th:nth-child(4),
      th:nth-child(5),
      th:nth-child(6) {
        flex: 0 0 auto !important;
        width: 10% !important;
        text-align: center;
        word-wrap: break-word !important;
      }
      th:nth-child(2),
      th:nth-child(3),
      th:last-child {
        flex: 1 1 auto !important;
        width: 20% !important;
        word-wrap: break-word !important;
      }
    }
    thead tr:last-child {
      th:nth-child(1) {
        border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
      }
      th {
        flex: 0 0 auto !important;
        width: 9% !important;
        text-align: center;
        word-wrap: break-word;
      }
    }
  }
}

// Mobile
@media screen and (max-width: 767px) {
  h1 {
    /* Mobile styles */
    font-size: 28px !important;
    text-align: center;
    span {
      font-size: 28px !important;
    }
  }
  p {
    text-align: center;
    font-size: 12px;
  }
  .navbarMenu {
    .ui.container {
      display: flex;
      justify-content: center;

      img {
        width: 50px;
      }
    }
  }
  .btnWrapperBanner {
    display: none !important;
  }
  .edImage {
    margin-top: 0 !important;
    width: 230px;
  }
  .mBtnWrapper {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .playBtn,
  .ui.massive.basic.button.leaderboardBtn {
    padding: 10px 42px 10px 42px !important;
    font-size: 17px;
    margin-bottom: 10px !important;
    margin-right: 0 !important;
    width: 80% !important;
    margin-top: 0;
  }
  .contentBannerWrapper {
    padding-bottom: 20px;
  }
  .socialIconWrapper {
    text-align: left !important;
  }
  .partnerForm {
    .field {
      margin-bottom: 10px !important;
    }
  }
  .leaderboardSegmentSearch {
    padding: 20px !important;
    .ui.container.dimmed.dimmable {
      margin: 0 !important;
      .resultSegment {
        margin-bottom: 0px !important;
        h2 {
          text-align: left;
          font-size: 16px !important;
        }
      }
    }
  }
  .searchInput {
    .result p {
      text-align: left !important;
    }
  }
  .detailWrapperLeaderboard {
    .column {
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 0 !important;
      padding-top: 10px !important;
      h1 {
        text-align: left !important;
        font-size: 20px !important;
      }
    }
  }
  .ui.table:not(.unstackable) tr {
    display: flex !important;
    align-items: center;
    border-radius: 10px;
    flex-wrap: wrap;
    td:nth-child(1),
    td:last-child {
      flex: 0 0 auto !important;
      width: 15% !important;
      text-align: center;
    }
    td:nth-child(2) {
      flex: 1 1 auto !important;
      width: 60% !important;
      word-wrap: break-word !important;
    }
  }
  .ui.large.table {
    font-size: 14px !important;
  }
  .ui.basic.celled.structured.table.tableAlliance {
    thead tr {
      box-shadow: none !important;
      border: 0px !important;
    }
    thead tr:last-child {
      justify-content: flex-end !important;
    }
    tbody tr {
      td:nth-child(1),
      td:nth-child(4),
      td:nth-child(5),
      td:nth-child(6),
      td:last-child {
        flex: 0 0 auto !important;
        width: 10% !important;
        text-align: center;
      }
      td:nth-child(2),
      td:nth-child(3) {
        flex: 1 1 auto !important;
        width: 20% !important;
        word-wrap: break-word !important;
      }
    }
  }
  .ui.basic.table.tableImprovement {
    thead tr {
      box-shadow: none !important;
    }
    tbody tr {
      td:nth-child(1),
      td:nth-child(5),
      td:last-child {
        flex: 0 0 auto !important;
        width: 10% !important;
        text-align: center;
      }
      td:nth-child(2),
      td:nth-child(3) {
        flex: 1 1 auto !important;
        width: 20% !important;
        word-wrap: break-word !important;
      }
    }
  }
}
